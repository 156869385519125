<template>
  <div class="wrapper">
    <div
      v-if="false !== false"
      class="modal"
      tabindex="-1"
      role="dialog"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
      "
    >
      <div
        style="
          background-color: var(--white);
          color: black;
          max-width: 468px;
          width: 85%;
          height: 40vh;
        "
        class="modal-content"
      >
        <div class="modal-body">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              style="width: 150px; margin-top: 60px"
              src="/img/check-icons.png"
              alt="Check"
            />
          </div>
          <br />
          <h5 style="text-align: center; color: var(--darkBackground)">
            SUCCESS.
          </h5>
          <br />
          <br />
        </div>
      </div>
    </div>

    <section
      class="register-form-wrapper p-4"
      v-show="action_login"
      style="background-color: #02032f"
    >
      <h3 style="text-align: center">
        <span style="color: var(--white)">LOGIN</span> <img style="width: 160px;margin-bottom: 0;" src="/longLogo.png">
      </h3>
      <p style="text-align: center">
        Enter your phone number and Password below
      </p>
      <div class="text-light text-center mb-3"></div>
      <div
        class="form-wrapper mb-3 mx-auto p-3"
        style="background-color: #07074f; border-radius: 5px"
      >
        <form class="">
          <!-- <div class="mb-3">
            <label class="text-light">First name</label>
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
              type="text" class="form-control" placeholder="John" aria-label="First name" name="mobile"
              v-model="first_name">
          </div>
          <div class="mb-3">
            <label class="text-light">Last name</label>
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
              type="text" class="form-control" placeholder="Doe" aria-label="Last name" name="mobile"
              v-model="last_name">
          </div> -->
          <div class="mb-0 d-flex justify-content-center">
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-phone"
                style="
                  position: absolute;
                  left: 10px;
                  top: 60%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                type="tel"
                placeholder="Phone Number (07xx or 2547xx)"
                aria-label="Enter Phone Number"
                id="phoneInput"
                name="mobile"
                v-model="msisdn"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;

                  height: 40px;
                "
              />
            </div>
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center m-auto"
            >Invalid phone number</small
          >
          <div class="mb-3"></div>

          <div class="mb-0 d-flex justify-content-center">
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-lock"
                style="
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                  background-color: white;
                "
              ></i>
              <input
                :type="type"
                placeholder="Enter Password"
                id="inputPass"
                aria-label="Enter Password"
                v-model="password"
                name="password"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;
                  margin-bottom: 10px;
                  height: 40px;
                "
              />
              <i
                class="bi bi-eye"
                style="
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
                @click="showPassword"
              ></i>
            </div>
          </div>

          <small class="text-blue mb-3 d-none"
            >Enter the 4 digit code sent to your phone</small
          >
          <input type="hidden" name="utm_source" value="" />
          <input type="hidden" name="utm_medium" value="" />
          <input type="hidden" name="utm_campaign" value="" />
          <input type="hidden" name="referrer" value="" />
        </form>

        <div class="disclaimer text-center" style="color: #8986cd">
          By continuing, you confirm that you are 18 years old or over and agree
          with the
          <a style="text-decoration: underline"
            >Terms and Conditions and Privacy Policy</a
          >
          of qwerty
        </div>

        <div class="button-wrapper text-center mb-3">
          <a
            v-if="!spinner"
            @click="handleLoginClick"
            class="join-button py-2 form-control"
            style="
              background-color: var(--yellow);
              color: black !important;
              font-weight: bold;
            "
            >Login</a
          >
          <a v-if="spinner" class="join-button py-2 form-control">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
              <br />
            </div>
          </a>
        </div>
      </div>

      <div style="background-color: #07074f; padding: 10px; border-radius: 5px">
        <p style="text-align: center">Did you forget your Password?</p>

        <button
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            padding: 6px;
            border: none;
            border: 1px solid var(--yellow);
            background-color: #07074f;
            color: var(--yellow);
            border-radius: 4px;
            width: 80px;
            cursor: pointer;
          "
          @click="setGetResetCode"
        >
          Reset
        </button>
      </div>

      <div style="background-color: #07074f; padding: 10px; border-radius: 5px">
        <p style="text-align: center">Don't Have an account?</p>
        <!-- <p style="text-align: center">Welcome Back!</p> -->

        <button
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            padding: 6px;
            border: none;
            border: 1px solid var(--yellow);
            background-color: #07074f;
            color: var(--yellow);
            border-radius: 4px;
            width: 80px;
            cursor: pointer;
          "
          @click="setSignup"
        >
          Join Now
        </button>
      </div>
    </section>

    <section
      class="register-form-wrapper p-4"
      v-show="action_signup"
      style="background-color: #02032f"
    >
      <h3 style="text-align: center">
        <span style="color: var(--white)">JOIN </span> 
        <img style="width: 160px;margin-bottom: 0;" src="/longLogo.png">
      </h3>
      <p style="text-align: center">
        Enter your phone number and Password below
      </p>
      <div class="text-light text-center mb-3"></div>
      <div
        class="form-wrapper mb-3 mx-auto p-3"
        style="background-color: #07074f; border-radius: 5px"
      >
        <form class="">
          <!-- <div class="mb-3">
            <label class="text-light">First name</label>
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
              type="text" class="form-control" placeholder="John" aria-label="First name" name="mobile"
              v-model="first_name">
          </div>
          <div class="mb-3">
            <label class="text-light">Last name</label>
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
              type="text" class="form-control" placeholder="Doe" aria-label="Last name" name="mobile"
              v-model="last_name">
          </div> -->
          <div class="mb-0 d-flex justify-content-center">
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-phone"
                style="
                  position: absolute;
                  left: 10px;
                  top: 60%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                type="tel"
                placeholder="Phone Number (07xx or 2547xx)"
                aria-label="Enter Phone Number"
                id="msisdn"
                v-model="msisdn"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;

                  height: 40px;
                "
              />
            </div>
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center m-auto"
            >Invalid phone number</small
          >
          <div class="mb-3"></div>

          <!-- <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="Enter Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--yellow)"
                  class="btn btn-sm"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div> -->

          <div class="mb-0 d-flex justify-content-center">
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-lock"
                style="
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                :type="type"
                placeholder="Enter Password"
                id="inputPass"
                aria-label="Enter Password"
                v-model="password"
                name="password"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;
                  margin-bottom: 10px;
                  height: 40px;
                "
              />
              <i
                class="bi bi-eye"
                style="
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
                @click="showPassword"
              ></i>
            </div>
          </div>

          <div class="mb-0 d-flex justify-content-center">
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-lock"
                style="
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                :type="type"
                placeholder="Enter Password"
                id="signupPassword1"
                aria-label="Confirm Password"
                v-model="password1"
                name="password_2"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;
                  margin-bottom: 10px;
                  height: 40px;
                "
              />
              <i
                class="bi bi-eye"
                style="
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
                @click="showPassword"
              ></i>
            </div>
          </div>

          <!-- <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword1"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password_2"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
                v-model="password1"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--yellow)"
                  class="btn btn-sm"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div> -->
          <!-- <div class="mb-0">
            <label class="text-light">Enter code</label>
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
              type="tel" class="form-control" placeholder="Enter referral code" aria-label="Enter referral code"
              name="mobile" id="referral_code" v-model="referral_code">
          </div> -->
        </form>

        <div class="disclaimer text-center" style="color: #8986cd">
          By registering, you confirm that you are 18 years old or over and
          agree with the
          <a style="text-decoration: underline"
            >Terms and Conditions and Privacy Policy</a
          >
          of qwerty
        </div>

        <div class="button-wrapper text-center mb-3">
          <a
            v-if="!spinner"
            @click="handleSignupClick"
            class="join-button py-2 form-control"
            style="
              background-color: var(--yellow);
              color: black !important;
              font-weight: bold;
            "
            >Get Registration code</a
          >
          <a v-if="spinner" class="join-button py-2 form-control">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
              <br />
            </div>
          </a>
        </div>
      </div>

      <!-- <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have an account? <a @click="setLogin"> <span style="text-decoration: underline;"> Login</span></a>
          <br>
        </div>
      </div> -->

      <div style="background-color: #07074f; padding: 10px; border-radius: 5px">
        <p style="text-align: center">Already Have an account?</p>
        <p style="text-align: center">Welcome Back!</p>
        <button
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            padding: 6px;
            border: none;
            border: 1px solid var(--yellow);
            background-color: #07074f;
            color: var(--yellow);
            border-radius: 4px;
            width: 80px;
            cursor: pointer;
          "
          @click="setLogin"
        >
          Login
        </button>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-light text-center">
        Registration <span class="text-light">Code</span>
      </h3>
      <div class="text-center text-light mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0">
            <label class="text-light">Input code</label>
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              placeholder="Enter code"
              id="code"
              aria-label="Enter Code"
              v-model="code"
            />
          </div>
          <small class="text-blue mb-3"
            >Enter the 4 digit code sent to your phone</small
          >
        </form>
      </div>

      <div class="disclaimer text-center text-light">
        By activating your qwerty account, you confirm that you are 18 years
        old or over and agree with the
        <a class="text-light">Terms, conditions and policies</a> of qwerty
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="handleVerifyClick" class="join-button py-2 form-control"
          >Activate Account</a
        >
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-light mb-3">
          Didn’t get an SMS code?<br />
          Let’s try again in<br />
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">Resend</a>
      </div>
    </section>

    <!-- RESET YOUR PASSWORD -->

    <section
      class="register-form-wrapper"
      v-show="action_reset_password"
      style="background-color: #02032f; padding-left: 5px; padding-right: 5px"
    >
      <h3 class="text-light text-center p-3">
        Reset your <span style="color: #fbcd00">Password</span>
      </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number and PIN Below
      </div>

      <div class="form-wrapper mb-3">
        <form>
          <div
            class="mb-3 mx-auto"
            style="
              background-color: #07074f;
              padding: 10px;
              width: 90%;
              border-radius: 6px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-phone"
                style="
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                type="tel"
                id="msisdn1"
                placeholder="Phone Number (07xx or 2547xx)"
                aria-label="Phone Number"
                v-model="msisdn"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;
                  margin-bottom: 10px;
                  height: 40px;
                "
              />
            </div>
            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-lock"
                style="
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                :type="type"
                placeholder="Password"
                id="inputPass"
                aria-label="Enter Password"
                v-model="password"
                name="password"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;
                  margin-bottom: 10px;
                  height: 40px;
                "
              />
              <i
                class="bi bi-eye"
                style="
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
                @click="showPassword"
              ></i>
            </div>

            <div
              style="
                position: relative;
                display: flex;
                width: 100%;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 500px;
              "
            >
              <i
                class="bi bi-lock"
                style="
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
              ></i>
              <input
                :type="type"
                id="inputPass"
                placeholder="Confirm Password"
                aria-label="Enter Password"
                v-model="password"
                name="password"
                class="form-control"
                style="
                  width: 100%;
                  padding-left: 40px;
                  padding: 6px;
                  margin-top: 10px;
                  border-radius: 20px;
                  border: none;
                  margin-bottom: 10px;
                  height: 40px;
                "
              />
              <i
                class="bi bi-eye"
                style="
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #d0d0d0;
                  font-size: 21px;
                "
                @click="showPassword"
              ></i>
            </div>

            <!-- 
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
                margin-bottom: 15px;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="Confirm Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--yellow)"
                  class="btn btn-sm"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div> -->

            <!-- <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="Enter Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--yellow)"
                  class="btn btn-sm"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div> -->
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
            class="button-wrapper text-center mb-3"
          >
            <button
              style="
                width: 100%;
                margin-bottom: 15px;
                padding: 7px;
                background-color: var(--yellow);
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-weight: bold;
                color: #020730;
                font-size: 15px;
              "
              v-if="!spinner"
              @click="handleResetClick"
            >
              Get Reset Code
            </button>
            <a v-if="spinner" class="join-button py-2 form-control">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
                <br />
              </div>
            </a>
          </div>
        </form>
      </div>

      <div style="display: flex; flex-direction: column; gap: 10px">
        <div
          style="background-color: #07074f; padding: 10px; border-radius: 5px"
        >
          <p class="reset-code-p">Already have a reset code?</p>
          <p class="reset-code-p">Proceed to verification</p>

          <button
            style="
              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
              background-color: #06064e;
              padding: 8px;
              cursor: pointer;
              border: none;
              color: white;
              border: 1px solid #2b2b71;
              border-radius: 4px;
            "
          >
            Enter SMS Code
          </button>
        </div>

        <div
          style="background-color: #07074f; padding: 8px; border-radius: 5px"
        >
          <p class="reset-code-p">Already have an account?</p>
          <p class="reset-code-p">Welcome Back!</p>
          <button
            style="
              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
              background-color: #06064e;
              padding: 8px;
              cursor: pointer;
              border: none;
              color: white;
              border: 1px solid #2b2b71;
              border-radius: 4px;
            "
            @click="setLogin"
          >
            Login
          </button>
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-light text-center">
        Reset your <span class="text-light">Password</span>
      </h3>
      <div class="text-center text-light mb-3">
        Password reset code has been send to your mobile number. Enter the code
        you have received in your phone and your new password.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <label class="text-light">Enter phone number</label>
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              id="phoneInput"
              placeholder="Phone number 07 or 01"
              aria-label="Enter Phone number"
              name="mobile"
              v-model="msisdn"
            />
          </div>
          <div class="mb-3">
            <label class="text-light">Input code</label>
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              placeholder="Enter code"
              id="code1"
              aria-label="Enter Code"
              v-model="code"
            />
          </div>
          <div class="mb-3">
            <label class="text-light">Input password</label>
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword3"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="New Password"
                aria-label="New Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--yellow)"
                  class="btn btn-sm"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <div class="mb-0">
            <label class="text-light">Confirm password</label>
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 40px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword1"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password_2"
                placeholder="Confirm New Password"
                aria-label="Confirm New Password"
                v-model="password1"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--yellow)"
                  class="btn btn-sm"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="disclaimer text-center d-none text-light">
        By continuing, you confirm that you are 18 years old or over and agree
        with the <a class="text-light">Terms, conditions and policies</a> of
        qwerty
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleChangePasswordClick"
          class="join-button py-2 form-control"
          >Change Password</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1">
        <div class="text-center text-light mb-3">
          Already have an account?
          <a @click="setLogin">
            <span style="text-decoration: underline">Login</span></a
          ><br />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: 0.5em;
  height: 3em;
}

label {
  font-weight: 600;
}
</style>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";

export default {
  name: "Login",
  components: {},
  comments: {},
  data: function () {
    return {
      first_name: "",
      last_name: "",
      msisdn: "",
      password: "",
      password1: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      action_login: false,
      action_signup: true,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: "",
      placeBet: 0,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
    };
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  methods: {
    handleLoginClick(event) {
      this.login(event);
    },
    trackLoginButtonClick(event) {
      pushToDataLayer("gtm.login", {
        category: "Button",
        action: "Click",
        label: "Login",
        element: event.target,
      });
    },

    login: function (event) {
      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password.length < 4) {
        this.$toast.open({
          message: "Please enter a password of more than 4 characters",
          type: "error",
          position: "top",
        });
        return;
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      console.log("Login method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Payload:", {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      });
      axios
        .post(
          path,
          JSON.stringify({
            msisdn: parseInt(this.msisdn),
            password: this.password,
            login_tag: login_tag,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
          })
        )
        .then((res) => {
          vm.loading = "";
          vm.spinner = false;

          var profile = res.data;
          var status = res.status;

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.trackLoginButtonClick(event);

          this.$toast.open({
            message: "Login successful!",
            type: "success",
            position: "top",
          });

          vm.EventBus.$emit("init:mqtt");

          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          vm.spinner = false;

          if (err.response) {
            this.$toast.open({
              message: "Login failed!",
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },
    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },

    getBrowserInfo() {
      return navigator.userAgent || "unknown";
    },

    getDeviceInfo() {
      return `${navigator.platform} ${navigator.userAgent}`;
    },

    getEngineInfo() {
      return "JavaScript";
    },

    getVersionInfo() {
      return navigator.appVersion || "unknown";
    },

    handleSignupClick(event) {
      this.signup(event);
    },
    trackSignupButtonClick(event) {
      pushToDataLayer("gtm.register", {
        category: "Button",
        action: "Click",
        label: "Register",
        element: event.target,
      });
    },
    signup: function (event) {
      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password.length < 4) {
        this.$toast.open({
          message: "Please enter a password of more than 4 characters",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password !== this.password1) {
        this.$toast.open({
          message: "Your passwords do not match",
          type: "error",
          position: "top",
        });
        return;
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;
      var path = "/signup";

      var browser = this.getBrowserInfo();
      var deviceInfo = this.getDeviceInfo();
      var engine = this.getEngineInfo();
      var versionInfo = this.getVersionInfo();

      var channelId = parseInt(this.getValue("channel_id")) || 0;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var btag = this.getValue("btag");

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Referral Code:", this.referralCode);

      axios
        .post(
          path,
          JSON.stringify({
            browser: browser,
            channel_id: channelId,
            device_info: deviceInfo,
            engine: engine,
            version_info: versionInfo,
            msisdn: parseInt(this.msisdn),
            password: this.password,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
            referral_code: this.referralCode,
            btag: btag,
          })
        )
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log("Response:", JSON.stringify(res, undefined, 2));

          var status = res.status;

          if (parseInt(status) === 202) {
            vm.setLogin();
            return;
          }

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.trackSignupButtonClick(event);

          this.$toast.open({
            message: "Signup successful",
            type: "success",
            position: "top",
          });
          vm.setVerifyAccount();
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },
    handleResetClick(event) {
      this.getResetCode(event);
    },
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },
    getResetCode: function (event) {
      this.reset();

      if (this.msisdn.length < 9) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      var payload = {
        msisdn: parseInt(this.msisdn),
      };

      console.log("Request Payload:", payload);

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log(JSON.stringify(res));

          vm.trackResetButtonClick(event);

          this.$toast.open({
            message: "Password reset code has been sent to your phone",
            type: "success",
            position: "top",
          });

          vm.setChangePassword();
        })
        .catch((err) => {
          vm.spinner = false;
          console.log(JSON.stringify(err));

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },
    handleChangePasswordClick(event) {
      this.changePassword(event);
    },
    trackChangePasswordButtonClick(event) {
      pushToDataLayer("gtm.changePassword", {
        category: "Button",
        action: "Click",
        label: "Change Password",
        element: event.target,
      });
    },
    changePassword: function (event) {
      this.reset();

      if (this.password.length < 4) {
        this.$toast.open({
          message: "Please enter a password of more than 4 characters",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password !== this.password1) {
        this.$toast.open({
          message: "Your passwords do not match",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.code.length < 4) {
        this.$toast.open({
          message: "Please enter a valid verification code",
          type: "error",
          position: "top",
        });
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      var payload = {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        code: parseInt(this.code),
      };

      console.log("Request Payload:", payload);

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(JSON.stringify(res));

          vm.spinner = false;
          vm.loading = "";

          vm.trackChangePasswordButtonClick(event);

          this.$toast.open({
            message: "Your password has been changed successfully",
            type: "success",
            position: "top",
          });

          vm.setLogin();
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },
    handleVerifyClick(event) {
      this.verifyAccount(event);
    },
    trackVerifyButtonClick(event) {
      pushToDataLayer("gtm.verify", {
        category: "Button",
        action: "Click",
        label: "Verify",
        element: event.target,
      });
    },
    verifyAccount: function (event) {
      this.reset();

      if (this.code.length < 4) {
        this.$toast.open({
          message: "Please enter a valid verification code",
          type: "error",
          position: "top",
        });
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || "";
      var utm_medium = this.getValue("utm_medium") || "";
      var utm_campaign = this.getValue("utm_campaign") || "";

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      };

      console.log("Request Payload:", payload);

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.trackVerifyButtonClick(event);
          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          this.$toast.open({
            message:
              "Congratulations! Your account has been verified successfully",
            type: "success",
            position: "top",
          });

          vm.EventBus.$emit("init:mqtt");

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit("event:betslip:show");
          } else {
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            this.$toast.open({
              message: err.response.data.error_message,
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log("Error Request:", err.request);
          } else {
            this.$toast.open({
              message: "Check your network connection and try again",
              type: "error",
              position: "top",
            });
            console.log("Error Message:", err.message);
          }
        });
    },
    setSignup: function () {
      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setLogin: function () {
      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setGetResetCode: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;
    },
    setChangePassword: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setVerifyAccount: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
        document.getElementById("inputPass").style.color = "black";
        // white
      } else {
        document.getElementById("inputPass").type = "password";
        document.getElementById("inputPass").style.color = "black";
        // rgba(255,255,255,.75)
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },

    showPasswordReset: function () {
      if (document.getElementById("signupPassword3").type == "password") {
        document.getElementById("signupPassword3").type = "text";
        document.getElementById("signupPassword3").style.color = "white";
      } else {
        document.getElementById("signupPassword3").type = "password";
        document.getElementById("signupPassword3").style.color =
          "rgba(255,255,255,.75)";
      }
      if (this.type2 === "password") {
        this.type2 = "text";
        this.btnText2 = "/img/other/eyeslash.svg";
      } else {
        this.type2 = "password";
        this.btnText2 = "/img/other/eye.svg";
      }
    },
    showPasswordReset2: function () {
      if (document.getElementById("signupPassword4").type == "password") {
        document.getElementById("signupPassword4").type = "text";
        document.getElementById("signupPassword4").style.color = "white";
      } else {
        document.getElementById("signupPassword4").type = "password";
        document.getElementById("signupPassword4").style.color =
          "rgba(255,255,255,.75)";
      }
      if (this.type3 === "password") {
        this.type3 = "text";
        this.btnText3 = "/img/other/eyeslash.svg";
      } else {
        this.type3 = "password";
        this.btnText3 = "/img/other/eye.svg";
      }
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "join");
    this.placeBet = this.getValue("placeBet");
  },
};
</script>
